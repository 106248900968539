import request from "@/utils/request";
const BASE_API = process.env.VUE_APP_BASE_API;

// 发送请求监视数据
function postRequestMonitor(data) {
  return request({
    url: `${BASE_API}/api/response_monitor`,
    method: "post",
    data: data,
  })
}

function startDownload(url) {
  var startTime = (new Date()).getTime()
  var prevLoaded = 0 // 上次记录的已加载的数据量
  var speeds = [] // 存储过去30秒的下载速度
  var downloadTimeIntervalInMs = 30000 // 30秒
  let xhr = new XMLHttpRequest()
  xhr.open('GET', url, true)
  xhr.responseType = 'arraybuffer'
  // 设置请求超时时间为30秒
  xhr.timeout = 30000
  xhr.onprogress = function(event) {
      // 判断是否支持计算进度
      if (event.lengthComputable) { 
          var currentTime = (new Date()).getTime();
          var timeInterval = (currentTime - startTime) / 1000 // 总共用时(秒)
          startTime = currentTime
          var loaded = event.loaded; // 已加载的数据量
          var speed = (loaded - prevLoaded) / timeInterval / 1024 // kb/s
          prevLoaded = loaded
          speeds.push(speed)
      }
  }
  xhr.ontimeout = function() {
      console.log('请求已超时，尝试中断请求...')
      xhr.abort()
  }
  xhr.onload = function() {
      if (xhr.status == 200) {
          console.log('请求成功！')
      } else {
          console.log('请求失败：' + xhr.status)
      }
  }
  console.log('开始下载...')
  xhr.send(null)
  return new Promise((resolve, reject) => {
    setTimeout(function() {
        var sum = speeds.reduce(function(a, b) {
            return a + b
        }, 0)
        var avg = sum / speeds.length
        var avgDownSpeed = avg.toFixed(2) + ' KB/s'
        console.log(`过去30秒的平均下载速度为: ${avgDownSpeed}`)
        if (avgDownSpeed !== 'Infinity KB/s' && avgDownSpeed !== 'NaN KB/s') {
          resolve(avgDownSpeed)
        } else {
          reject('下载失败')
        }
    }, downloadTimeIntervalInMs)
  })
}

export function listenPerformance(self) {
  // 增加两个图片文件来判断网络状态
  const alicdn = 'https://img.alicdn.com/imgextra/i3/2065786850/O1CN01V8cXsw20TM4ZuEKjn_!!4611686018427381730-0-item_pic.jpg'
  const cdn = 'https://cdn.mktindex.com/tmp/pic/2020_08_19_100171513_1044387409_1.png'
  const mojingcdn = 'https://static.mktindex.com/mojing/images/invite.jpg'
  const resources = performance.getEntriesByType("resource")
  resources.forEach(async function(resource) {
    // 所有时间值均以毫秒为单位
    const url = resource.name
    const username = self.$store.state.user.loginInfo.username
    const domainLookupTime = resource.domainLookupEnd - resource.domainLookupStart
    const connectTime = resource.connectEnd - resource.connectStart
    const requestTime = resource.responseStart - resource.requestStart
    const responseTime = resource.responseEnd - resource.responseStart
    // 筛选请求接口
    if (
      resource.initiatorType == 'xmlhttprequest' &&
      url.includes('/api/') &&
      !url.includes('/api/response_monitor') &&
      username
    ) {
      // 超时就调用接口，把参数传给系统
      if (
        domainLookupTime > 60000 ||
        connectTime > 60000 ||
        requestTime > 60000 ||
        responseTime > 60000
      ) {
        const promiseAlicdn = startDownload(alicdn)
        const promiseCdn = startDownload(cdn)
        const promiseMojing = startDownload(mojingcdn)
        const responses = await Promise.all([promiseAlicdn, promiseCdn, promiseMojing])
        let alicdnSpeed = responses[0]
        let cdnSpeed = responses[1]
        let mojingSpeed = responses[2]
        let query = {
          browser_finger: localStorage.getItem('browserFinger')
        }
        let args = url.split('?')
        if (args[0] !== url) {
          let arr = args[1].split("&");
          query = {}
          arr.forEach((ele) => {
            let key = ele.split("=")[0]
            let value = ele.split("=")[1]
            query[key] = value
          })
        }
        const params = {
          url: url, // 请求url
          username: username, // 用户名
          start_time: '', // 开始时间
          dns_time: domainLookupTime, // DNS解析时间开销
          connect_time: connectTime, // 建立连接时间开销
          request_time: requestTime, // 服务器响应时间开销
          response_time: responseTime, // 下载数据时间开销
          duration: resource.duration, // 请求耗时
          query: JSON.stringify(query), // 请求参数
        }
        if (
          alicdnSpeed &&
          alicdnSpeed !== '' &&
          cdnSpeed &&
          cdnSpeed !== '' &&
          mojingSpeed && 
          mojingSpeed !== ''
        ) {
          params.alicdnSpeed = alicdnSpeed
          params.cdnSpeed = cdnSpeed
          params.mojingSpeed = mojingSpeed
          postRequestMonitor(params)
            .then((res) => {
              if (res.status == 'ok') {
                console.log(res)
              }
            })
            .catch((err) => {
              console.log(err)
            })
        }
      }
    }
  })
  // 发送resources后清空，避免重复发送
  performance.clearResourceTimings()
}

